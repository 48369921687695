<template>
  <div v-if="state === states.READY">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>Edit Tests</BasePageHeading>
      </div>
      <BasePageActions>
        <BaseButton
          variant="salmon"
          :loading="savingInProgress"
          @click="saveAssessmentExams()"
        >
          Finish
        </BaseButton>
      </BasePageActions>
    </BasePageHeader>

    <BaseTabBar>
      <div>
        <AssessmentExamPills
          :exams="assessment.exams"
          :custom-exams="assessment.customExams"
          :max-exams="maxExams"
          @removeExam="removeExamFromAssessment($event)"
          @removeCustomExam="removeCustomExamFromAssessment($event)"
        />
      </div>
      <div class="w-1/3">
        <Select
          placeholder="Filter Tests"
          :options="filterOptions"
          @selected="filterBy($event)"
        >
        </Select>
      </div>
    </BaseTabBar>

    <ExamsLibrary
      :use-add-option="true"
      :assessment-exam-slugs="assessmentExamSlugs"
      :assessment-custom-exam-slugs="assessmentCustomExamSlugs"
      :exams-limit-reached="examsLimitReached"
      @addExam="addExamToAssessment($event)"
      @addCustomExam="addCustomExamToAssessment($event)"
    />
  </div>
  <div v-else-if="state === states.LOADING">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs></BasePageBreadcrumbs>
        <BasePageHeading></BasePageHeading>
      </div>
    </BasePageHeader>
    <Loader />
  </div>
  <div v-else-if="state === states.ERROR">
    <BaseErrorBlock />
  </div>
</template>

<script>
import ExamsLibrary from '@components/Exams/ExamsLibrary'
import AssessmentExamPills from '@components/ClientAssessments/AssessmentExamPills'
import Loader from '@components/Loader'
import Select from '@components/TUI/Select'

import { mapGetters } from 'vuex'
import states from '@api/states'
import jobsApi from '@api/jobs'

export default {
  components: {
    ExamsLibrary,
    AssessmentExamPills,
    Loader,
    Select
  },

  data() {
    return {
      states,

      error: null,
      assessment: null,
      maxExams: 6,

      savingInProgress: false
    }
  },

  computed: {
    ...mapGetters({
      organisationName: 'organisations/name',
      clientExamsState: 'clientExams/state',
      customExams: 'clientExams/customExams',
      examSuiteOptions: 'clientExams/examSuiteOptions'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      return [
        {
          name: this.organisationName
        },
        {
          name: 'Assessments',
          to: 'client-assessments-list'
        },
        {
          name: this.assessment.name,
          to: 'client-assessments-show',
          params: {
            id: this.assessment.uuid
          }
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }
      if (this.clientExamsState === states.ERROR) {
        return states.ERROR
      }
      if (!this.assessment) {
        return states.LOADING
      }

      return states.READY
    },

    /**
     * @return {Array}
     */
    filterOptions() {
      const options = this.examSuiteOptions

      if (this.assessment.customExams) {
        options.push({
          label: 'Custom Tests',
          value: 'custom-exams'
        })
      }

      return options
    },

    /**
     * Have we already got enough exams for this assessment?
     */
    examsLimitReached() {
      return (this.assessment.exams.length + this.assessment.customExams.length) >= this.maxExams
    },

    /**
     * @return {Array}
     */
    assessmentExamSlugs() {
      return this.assessment.exams.map(exam => exam.slug)
    },

    /**
     * @return {Array}
     */
    assessmentCustomExamSlugs() {
      return this.assessment.customExams.map(exam => exam.slug)
    }
  },

  created() {
    this.fetchAssessment()
  },

  mounted() {
    this.filterBy()
  },

  methods: {
    /**
     * Fetch the assessment based on the ID
     *
     * @return {Promise}
     */
    fetchAssessment() {
      this.assessment = null

      return jobsApi.job(this.$route.params.id)
        .then(response => {
          this.assessment = response.job
        })
        .catch(error => {
          this.error = error
          throw error
        })
    },

    /**
     * Adds an exam to the assessment
     *
     * @param {Object} exam
     */
    addExamToAssessment(exam) {
      if (this.assessment.exams.indexOf(exam) >= 0) {
        console.log(exam.slug + ' has already been added')
        return
      }
      this.assessment.exams.push(exam)
    },

    /**
     * Adds a custom exam to the assessment
     *
     * @param {Object} exam
     */
    addCustomExamToAssessment(exam) {
      if (this.assessment.customExams.indexOf(exam) >= 0) {
        console.log(exam.slug + ' has already been added')
        return
      }
      this.assessment.customExams.push(exam)
    },

    /**
     * Removes an exam to the assessment
     *
     * @param {Object} exam
     */
    removeExamFromAssessment(exam) {
      this.assessment.exams.splice(this.assessment.exams.indexOf(exam), 1)
    },

    /**
     * Removes an exam to the assessment
     *
     * @param {Object} exam
     */
    removeCustomExamFromAssessment(exam) {
      this.assessment.customExams.splice(this.assessment.customExams.indexOf(exam), 1)
    },

    saveAssessmentExams() {
      if (this.savingInProgress) {
        console.error('Already saving')
        return
      }

      this.savingInProgress = true

      return jobsApi.update(this.$route.params.id, {
        name: this.assessment.name,
        exams: this.assessmentExamSlugs,
        customExams: this.assessmentCustomExamSlugs
      })
        .then(response => {
          this.$router.push({ name: 'client-assessments-show', params: { id: this.assessment.uuid } })
        })
        .catch(error => {
          this.error = error
          throw error
        })
    },

    /**
     * Re-dispatch client exams based on the current filter
     *
     * @param {string} examSuiteSlug
     * @return void
     */
    filterBy(examSuiteSlug) {
      this.$store.dispatch('clientExams/getClientExams', examSuiteSlug)
    }
  }
}
</script>
